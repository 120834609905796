import {
  SET_APPLICATIONS,
  SET_LOOKBOOK_IMAGES,
  SET_TOKEN,
  SET_USERNAME,
} from "./actions";

export type Lookbookdata = {
  lookbookdescription: string;
  looknumber: number;
  lookbookurl: string;
};

export type Applicationdata = {
  appname: string;
  appdescription: string;
  open: boolean;
  appurl: string;
  type: string;
  inputtable: boolean;
};

export type InitialState = {
  name: string;
  token: string;
  applications: Applicationdata[];
  lookbookImages: Lookbookdata[];
};

const initialState: InitialState = {
  name: "",
  token: "",
  applications: [],
  lookbookImages: [],
};

type Action = { type: string; payload: any };

// Reducer
export const reducer = (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case SET_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };
    case SET_LOOKBOOK_IMAGES:
      return {
        ...state,
        lookbookImages: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
