import axios from "axios";

const API = "https://rowmgamzu3.execute-api.eu-west-1.amazonaws.com/prod/home";
// const API = "http://localhost:4005/dev/home";

axios.defaults.baseURL = API;

const getApplications = (token: string) =>
  axios.get(`/getApplications`, {
    headers: {
      Authorization: token,
    },
  });

const getLookBookImages = (token: string) =>
  axios.get(`/getLookBookImages`, {
    headers: {
      Authorization: token,
    },
  });

export default { getApplications, getLookBookImages };
