import "./App.scss";
import "./Global.scss";
import { HeaderV2 } from "@alexandermcqueengit/amq-ui-package";
import logo from "../src/assets/logo.svg";
import Grid from "@mui/material/Grid";
import { useEffect, useRef } from "react";
import RetailApps from "./Components/RetailApps/RetailApps";
import WholesaleApps from "./Components/WholesaleApps/WholesaleApps";
import { Amplify, Auth, Hub } from "aws-amplify";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  getApplications,
  getLookbookImages,
  setToken,
  setUsername,
} from "./app/actions";
import { InitialState } from "./app/reducer";
import Chat from "./Components/Chat/Chat";

const cognito = {
  domain: "amq-sso",
  user_pool: "eu-west-1_92QGqv2bj",
  user_pool_client: "7kn0b20ec0kfosg281amhggrth",
};

const url =
  process.env.NODE_ENV === "development"
    ? "https://localhost:3000"
    : "https://www.mcqueen-corp.com";

Amplify.configure({
  Auth: {
    oauth: {
      domain: `${cognito.domain}.auth.eu-west-1.amazoncognito.com`,
      responseType: "code",

      redirectSignIn: url,
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    },
    region: "eu-west-1",
    userPoolId: cognito.user_pool,
    userPoolWebClientId: cognito.user_pool_client,
  },
});

const App = () => {
  const dispatch = useAppDispatch();
  const trackRef = useRef<HTMLDivElement>(null);
  const name = useAppSelector((state: InitialState) => state.name);
  const token = useAppSelector((state: InitialState) => state.token);
  const lookbookImages = useAppSelector(
    (state: InitialState) => state.lookbookImages
  );

  Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
      
      case "customOAuthState":
        console.log("GETTING REDIRECT INFORMATION", payload.data)
        const state = JSON.parse(payload.data);
        window.location.href = 'https://www.mcqueen-corp.com' + state.redirectTo.replace(/_/g, "/");
        break;
    }
  });

  const getCurrentSession = () => {
    return Auth.currentSession()
      .then(async (session: any) => {
        dispatch(setToken(session.idToken.jwtToken));
        localStorage.setItem("cognitoToken", session.idToken.jwtToken);
        console.log("current user", await Auth.currentAuthenticatedUser())
        const { attributes } = await Auth.currentAuthenticatedUser();
        dispatch(
          setUsername(`${attributes.given_name} ${attributes.family_name}`)
        );
      })
      .catch((err) => {
        console.log("current session failed");
        console.log(err);
        Auth.federatedSignIn({ customProvider: "kering-sso", })
        return err;
      });
  };

  useEffect(() => {
    getCurrentSession();
  }, []);

  // useEffect(() => {
  //   if (trackRef.current) {
  //     const images = trackRef.current.querySelectorAll("img");
  //     images.forEach((img) => {
  //       const clone = img.cloneNode(true);
  //       trackRef.current!.appendChild(clone);
  //     });
  //   }
  // }, []);

  return (
    <div className="App">
      <HeaderV2 handleClick={() => { }} name={name} logo={logo} />
      <h1 className="App__title">A platform to innovate.</h1>

      {/* <Chat /> */}
      {/* <div className="App__carousel-container">
        <div className="App__carousel-track" ref={trackRef}>
          {lookbookImages.map((img) => (
            <img src={img.lookbookurl} alt="Image 1" />
          ))}
        </div>
      </div>
      <Grid className="App__text-grid" container spacing={2}>
        <Grid item xs={12} md={6}>
          <RetailApps />
        </Grid>
        <Grid item xs={12} md={6}>
          <WholesaleApps />
        </Grid>
      </Grid>
      <p className="App__footer">
        {lookbookImages.length && lookbookImages[0].lookbookdescription}
      </p> */}
    </div>
  );
};

export default App;
