import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import API from "../API";
import { Applicationdata, Lookbookdata } from "./reducer";

// Action Type
export const SET_USERNAME = "setUsername";
export const SET_TOKEN = "setToken";
export const SET_APPLICATIONS = "setApplications";
export const SET_LOOKBOOK_IMAGES = "setLookbookImages";

// Action Creators

export const setUsername = (name: string) => ({
  type: SET_USERNAME,
  payload: name,
});

export const setToken = (token: string) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setApplications = (applications: Applicationdata[]) => ({
  type: SET_APPLICATIONS,
  payload: applications,
});

export const setLookbookImages = (images: Lookbookdata[]) => ({
  type: SET_LOOKBOOK_IMAGES,
  payload: images,
});

export const getApplications = (token: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const { data: applications } = await API.getApplications(token);
      dispatch({ type: SET_APPLICATIONS, payload: applications });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getLookbookImages = (token: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const { data: images } = await API.getLookBookImages(token);
      dispatch({ type: SET_LOOKBOOK_IMAGES, payload: images });
    } catch (err) {
      console.log(err);
    }
  };
};
